@charset "UTF-8";
@import "utilities/variables";
@import "utilities/mixins";
*{margin:0;padding:0;-webkit-text-size-adjust:none;word-break:keep-all;box-sizing:border-box;}
*, *:after, *:before {-webkit-box-sizing:border-box; box-sizing:border-box;}
html, body {/* -webkit-font-smoothing:antialiased; */-webkit-text-size-adjust:none; -webkit-overflow-scrolling: touch}
html{overflow-y:scroll;-ms-overflow-x:hidden;-webkit-tap-highlight-color:unset !important;}
html.laypop{overflow-y:hidden;} /* 전체 레이어팝업 일때 */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, textarea, p, blockquote, table, th, td, input, select, textarea, button {margin:0;padding:0;word-break:break-all}
body, input, textarea, select, button, table {font-family:'Noto Sans', sans-serif; color:#000; font-size:16px; line-height:1;}
em {font-style:normal}
fieldset, img {border:0 none}
img  {vertical-align:middle}
dl, ul, ol, menu, li {list-style:none}
input::-ms-clear {display:none}
button {border:0 none; border-radius:0; background-color:transparent; cursor:pointer}
a {color:inherit; text-decoration:none;}
a:hover,
a:focus,
a:active {background-color:transparent; text-decoration:none;color: inherit;outline-offset:-1px;}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block;margin:0;padding:0;}
a[href^="tel:"] {
  cursor:text;
}
/* ios pinch-zoom */
body {
  touch-action: pan-x pan-y;
  &.pinch-zoom {
    touch-action: auto;
  }
}
legend, caption {position:absolute;z-index:-10;width:0 !important;height:0 !important;overflow:hidden !important;font-size:medium;line-height:normal;word-break:break-all}
.blind {position:absolute;z-index:-10;width:1px !important;height:1px !important;overflow:hidden !important;font-size:inherit;line-height:inherit;word-break:break-all;clip: rect(0, 0, 0, 0);clip-path: polygon(0 0, 0 0, 0 0);white-space:nowrap;transform:scale(0.01);}
.ios_no_acc{-webkit-overflow-scrolling:auto;}

// ::selection {
//   background:#101010;
//   color: #BDD61A;
//   text-shadow: none;
// }
// ::-moz-selection {
//   background:#101010;
//   color: #BDD61A;
//   text-shadow: none;
// }
// ::-webkit-selection {
//   background:#101010;
//   color: #BDD61A;
//   text-shadow: none;
// }

/* form */
input, select, textarea, button {
  vertical-align:middle;
  border:none;
  border-radius: 0;
  -webkit-appearance:none;
  -moz-appearance:none;appearance:none;
}
label {
  vertical-align:middle
}
textarea {
  width:100%;
  border:0;
  overflow-y:auto;
  resize:none
}

/* dimmed */
.dimmed {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
    background-color:#111;
    opacity:0.7;
    z-index:1000;
}

.loading_con {
  //  BTOCSITE-57393 [디자인] 닷컴 전체 로딩 모션, Dim 개선
  position: relative;
  width: 80px; 
  height: 80px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  @include mobile {
    transform: translate(-50%, -50%) scale(0.63);
  }
  
  // S :  BTOCSITE-57393 [디자인] 닷컴 전체 로딩 모션, Dim 개선
  .svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: center;
  }
  .border {
    fill: none;
    stroke: #ccc;
    stroke-width: 5;
    opacity: 0.6;
    @include mobile {
      stroke-width: 6;
    }
  }
  .progress {
    fill:none;
    stroke: #222;
    stroke-width: 5;
    stroke-dasharray: 207.24;
    stroke-dashoffset: 197;
    transform-origin: center; 
    transform: rotate(-90deg);
    animation: progress 1.2s ease-in-out infinite;
    @include mobile {
      stroke-width: 6;
    }
  }

  @keyframes progress {
    15% {
      stroke-dashoffset: 197;
    }
    50% {
      stroke-dashoffset: 157;
    }
    95% {
      stroke-dashoffset: 197;
    }
    100% {
      transform: rotate(260deg);
    }
  }
  // E :  BTOCSITE-57393 [디자인] 닷컴 전체 로딩 모션, Dim 개선

  
  .loading_dot {
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-left: 10px;
    &:nth-child(1){
      // left: 0px;
      margin-left: 0;
    }
  }
  
  .ui-spinner-msg {
    top: 15px;
  }

  @include mobile {
    .loading_dot {
      width: 17px;
      height: 17px;
      display: inline-block;
      margin-left: 21px;
      &:nth-child(1){
        // left: 0px;
        margin-left: 0;
      }
    }
  }
}

// BTOCSITE-5938-285 ,BTOCSITE-5938-592 scroll-lock 클래스 추가 : 팝업 뛰울시 바닥페이지 스크롤 밀림 방지 class
.scroll-lock {
  position: fixed;
  overflow: hidden;
  width:100vw;
  height:100vh;
}

